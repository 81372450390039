import React from "react";
import './about.css';


export default function About() { 

return (
    <div className="about">
        <h1>About</h1>

    </div>
)


}